<script>
import checkoutMixin from '@/v-shop/checkout/checkout-mixin'
export default {
	mixins: [checkoutMixin],
	data() {
		return { btnStatus: 'default', redirecting: false }
	},
	methods: {
		async submit() {
			if ((await this.checkout.beforeConfirm()) === false) return
			this.btnStatus = 'loading'
			const response = await this.checkout.stepSubmit()
			const initPoint = response?.data?.stepResponse?.initPoint
			if (initPoint) {
				window.location.href = initPoint
				this.redirecting = true
			} else {
				this.btnStatus = 'error'
			}
		},
	},
}
</script>
<template>
	<div>
		<div>
			<b> Serás redirigido a la plataforma de Open Pay para finalizar tu compra. </b>
		</div>
		<div class="d-flex justify-center justify-sm-end pt-6">
			<IndeterminateProgressBtn
				v-model="btnStatus"
				default-color="cta"
				success-color="success"
				error-color="error"
				@click="submit()"
				:error-duration="5000"
				block
				x-large
			>
				Realizar Pago
				<template #loading>
					{{ redirecting ? 'Redirigiendo a Open Pay...' : 'Generando Link de Pago...' }}
				</template>
				<template #success>
					<div></div>
				</template>
				<template #error>
					<v-icon left> mdi-close-thick </v-icon>
					Lo sentimos, ha ocurrido un error
				</template>
			</IndeterminateProgressBtn>
		</div>
	</div>
</template>

<style></style>

